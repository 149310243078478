<template>
  <div class="banner">
     <Header></Header>
    <div class="banner-width">
      <div>
        <Banner></Banner>
      </div>
       
    </div>
    <!-- 审核中 -->
    <div class="check-under">
      <div class="check-under-center">
        <div class="check-under-center-top">
          <!-- 审核中 -->
          <div class="check-font-button" v-show="audit_status == 1">
            <img src="@/icons/svg/audit/审核中120X120.svg" alt="">
            <h1>审核中</h1>
            <div class="check-font-button-fonts">
              <span>平台将在3个工作日内审核您的发布</span><br>
              <strong>审核期内，您可以<span @click="toUpdate" style="color:#00A2E6;cursor: pointer;">修改</span>您的发布需求，若有变动，也可以将该服务直接<span style="color:#e4007f;cursor: pointer;">关闭</span></strong>
            </div>
            <div class="check-succeed">
              <button @click="toInfo">查看详情</button>
            </div>
          </div>
          <!-- 审核成功 -->
          <div class="check-succeed-button" v-show="audit_status == 9" >
            <img src="@/icons/svg/audit/成功120X120.svg" alt="">
            <h1>审核成功</h1>
            <p>恭喜您，您的服务已经成功发布，雇主方看到您的服务后，就可能和您取得联系！</p>
            <div class="check-succeed">
              <button>返回我的主页</button>
            </div>
          </div>
          <!-- 审核失败 -->
          <div class="check-success-button" v-show="audit_status == 2">
            <img src="@/icons/svg/audit/失败120X120.svg" alt="">
            <h1>审核失败</h1>
            <p>{{ audit_memo }}</p>
            <div class="check-succeed">
              <button @click="toUpdate">返回修改</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>  
  </div>
</template>

<script>
import { getServiceAuditResult } from '@/api/service'
import Banner from "@/components/Banner"
export default {
  components:{
    Banner
  },
  data(){
    return {
      serviceId : 0,
      audit_status: 1,
      audit_memo: ''
    }
  },
  created(){
    this.serviceId = this.$route.params.id
    this.loadServiceResult()
  },
  methods:{
    async loadServiceResult(){
      const res = await getServiceAuditResult({id:this.serviceId}, this.$root.current_token)
      if( res.statusCode == 200 ){
        this.audit_status = res.data.audit_status
        this.audit_memo = res.data.audit_memo
      }
    },
    toUpdate(){
      this.$router.push( '/service/update/'+this.serviceId)
    },
    toInfo(){
      this.$router.push( '/service/main/'+this.serviceId)
    }
  }
};
</script>

<style lang="less" scoped>
.banner{
  background-color: #f4f4f4;
}
.banner-width {
  width: 100%;
  margin-top: -20px;
  .banner-img-font {
    background-image: url(~@/assets/banner/底图.jpg);
    width: 100%;
    height: 400px;
    background-size: cover;
    p {
      position: relative;
      width: 800px;
      margin: 0 auto;
      top: 60px;
      color: #fff;
      strong {
        font-size: 24px;
        display: block;
        margin-bottom: 23px;
      }
      li {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}

.check-under{
  width: 800px;
  background: #FFFFFF;
  height: 461px;
  margin: 0 auto;
  margin-top: -175px;
  .check-under-center{
    width: 720px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .check-under-center-top{
    margin-top: 100px;
  }
  /* 审核中 */
  .check-font-button{
    width: 500px;
    text-align: center;
    margin: 0 auto;
    img{
      width: 120px;
      height: 120px;
      margin-bottom: 26px;
    }
    span,strong{
      font-size: 12px;
      color: #575757;
    }
    .check-font-button-fonts{
      margin-top: 23px;
      strong{
        display: block;
        margin-top: 10px;
        margin-bottom: 40px;
      }
    }
    .check-butt{
      width: 300px;
      height: 36px;
      margin: 0 auto;
      button{
        width: 300px;
        line-height: 36px;
        font-size:18px;
        color: #fff;
        background-color: #00A2E6;
      }
    }
  }
  /* 审核成功 */
  .check-succeed-button{
    width: 500px;
    margin: 0 auto;
    text-align: center;
    img{
      width: 120px;
      height: 120px;
      margin-bottom: 26px;
    }
    p{
      font-size: 12px;
      color: #575757;
      margin-bottom: 40px;
    }
    h1{
      margin-bottom: 23px;
    }
    
  }
  /* 审查失败 */
  .check-success-button{
    width: 500px;
    margin: 0 auto;
    text-align: center;
    img{
      width: 120px;
      height: 120px;
      margin-bottom: 26px;
    }
    h1{
      margin-bottom: 23px;
    }
    p{
      font-size: 12px;
      color: #575757;
      margin-bottom: 40px;
      color: #e4007f;
    }
  }
}
.check-succeed{
      width: 300px;
      height: 36px;
      margin: 0 auto;
      button{
        width: 300px;
        line-height: 36px;
        background-color: #00A2E6;
        cursor: pointer;
        color: #fff;
        font-size: 18px;
      }
}
</style>